<template>
    <div class="flats apartments page">  
         <b-container fluid="xl">
            <b-row>
                <b-col cols="12">
                    <router-link class="go-back animateScroll" :to="{name: 'levels', params:{building_name: currentBuilding}}" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')">
                        <img src="@/assets/images/icons/ic-arrow-right-black-big.svg" />
                        {{tr('choose_floor_flat_back')}}
                    </router-link>
                    <h2 class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-1')" v-html="tr('choose_building_title')"></h2>        
                    <p class="page-lead animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')">{{tr('choose_floor_flat_lead')}}</p>      
                            
                    <!-- MOBILE -->
                    <div class="flats__choose--mobile d-md-none">
                        <button class="flats__mobile-btn animateScroll" type="button" @click="toggleFlats()" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-4')">
                            {{tr('choose_floor_flat_lead')}}
                            <img src="@/assets/images/icons/ic-arrow-down-black.svg" />
                        </button>
                        <ul v-if="showMobileFlats">
                            <li v-for="(flat, apIndex) in flats" :key="apIndex">
                                <router-link class="animateScroll" :to="{ name: 'flatDetails', params:{ level: currentLevel, building_name: currentBuilding, flat_slug: flat.slug }}" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')">
                                    <span v-html=" flat.name"></span><br>
                                    <small class="balcon-size">{{tr('choose_floor_flat_balcon')}}: {{balconSize(flat)}}</small>
                                </router-link>  
                            </li>
                        </ul>
                    </div>
                    <!-- DESCTOP -->
                    <b-tabs 
                        class="d-none d-md-flex"
                        no-fade
                        v-model="tabIndex" 
                        content-class="apartments__tab-content "                         
                        nav-wrapper-class="apartments__tab-head"
                    >                 
                        <b-tab>
                            <template v-slot:title>                                
                                <div @mouseover="showCurrentTab(-1)">
                                    {{tr('choose_floor_flat_all')}}
                                </div>
                            </template>                            
                            <img :src="baseUrl + currentLevelInfo.levelFlatsBase[selectedLanguage]" class="w-100 d-none d-md-block" />
                        </b-tab>            
                        <b-tab v-for="(flat, apIndex) in flats" :key="apIndex">
                            <template v-slot:title>
                                <div @mouseover="showCurrentTab(apIndex)">
                                    <router-link class="animateScroll" :to="{ name: 'flatDetails', params:{ level: currentLevel, building_name: currentBuilding, flat_slug: flat.slug }}" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', `delay-${apIndex+1}`)">
                                        <span v-html="flat.name"></span><br>
                                        <small class="balcon-size">{{tr('choose_floor_flat_balcon')}}: {{balconSize(flat)}}</small>                                       
                                    </router-link>                                    
                                </div>
                            </template>   
                            <div class="flats__content">
                                <img :src="baseUrl + currentLevelInfo.levelFlatsBase[selectedLanguage]" class="w-100 d-none d-md-block base-img" />    
                                <img :src="baseUrl + flat.flatsImgSrc" :alt="flat.name" class="w-100 d-none d-md-block flat-img" />
                            </div>                                                     
                        </b-tab>                                                                                      
                    </b-tabs>        
                </b-col>                                    
            </b-row>
        </b-container> 
        <div class="bgr-img animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')">
            <img src="@/assets/images/bgr/panorama-flats-mobile.jpg" />
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            flats:[],
            currentLevelInfo:{},
            tabIndex: -1,
            showMobileFlats: false
        }
    },
    computed:{
        apartments(){
            return this.$store.state.apartments
        },
        currentBuilding(){
            return this.$route.params.building_name            
        },
        currentLevel(){
            return this.$route.params.level            
        },
    },
    methods:{
        balconSize(flat){
            // console.log(flat);
            var terasz = 0;
            for (var j = 0; j < flat.flatsFloor.length; j++){
                for (var i=flat.flatsFloor[j].details.length -1 ; i > 0; i--)
                {
                    if (flat.flatsFloor[j].details[i].placeName['hu'] == 'Terasz')
                    {
                        terasz +=  parseFloat(flat.flatsFloor[j].details[i].placeSize);
                    }
    
                }
            }
            return terasz + " m²";
        },
        showCurrentTab(index){            
            this.tabIndex = index + 1
        },
        getLevels(){                     
            let _this = this
            let currentBuildingLevel = this.apartments.find( item => item.slug === _this.currentBuilding).levels
            _this.currentLevelInfo = currentBuildingLevel.find( item => item.slug === _this.currentLevel)
            _this.flats = currentBuildingLevel.find( item => item.slug === _this.currentLevel).flats

            this.needFlatsChooser()
        },
        toggleFlats(){
            this.showMobileFlats = !this.showMobileFlats
        },
        needFlatsChooser(){
            let _this = this

            if (this.flats.length < 2){
                this.$router.push({name: 'flatDetails', params:{building_name: _this.currentBuilding, level: _this.currentLevel, flat_slug: _this.flats[0].slug}})
            }
        }
    },
    created(){
        this.getLevels()        
    }
}
</script>